import { Langues } from '@/interfaces/Langues'
import { VuexStore, UserLogin, PredatosData, EventoState } from '@/interfaces/VuexStore'
import { CardInterface } from "@/interfaces/CardInterface";

const mutations = {
  setLang(state: VuexStore, locale: Langues) {
    state.locale = locale
  },
  SET_EVENT(state: VuexStore, data: EventoState) {
    state.evento = data
  },
  SET_DOCUMENTO(state: VuexStore, documento: string) {
    if (state.authUser) {
      state.authUser.documento = documento
    }
  },
  SET_FIDELIZAR(state: VuexStore) {
    if (state.authUser) {
      state.authUser.fidelizado = true
    }
  },
  SET_USER(state: VuexStore, user: UserLogin) {
    state.authUser = user
  },
  LOGOUT(state: VuexStore) {
    state.authUser = undefined
    state.navigation.preDatos = undefined
  },
  SET_RETURN(state: VuexStore, value: string) {
    state.returnUrl = value
  },
  ADD_TRACK(state: VuexStore, value: string) {
    state.track.push({ to: value, time: new Date().getTime() })
  },
  CLEAR_TRACK(state: VuexStore) {
    state.track = []
  },
  // COMPLETE_DATE(state: VuexStore, date: string) {
  //   state.date = date
  // },
  COMPLETE_UBICACION(state: VuexStore, card: CardInterface) {
    if (state.navigation.ubicacion?.id === card.id) {
      return
    }

    state.navigation = Object.assign(state.navigation, {
      ubicacion: card,
      actividad: undefined,
      vehiculo: undefined,
      turno: undefined,
      date: undefined,
      datosPersonales: undefined,
      preDatos: (!state.authUser) ? undefined : state.navigation.preDatos
    })
  },
  COMPLETE_ACTIVIDAD(state: VuexStore, card: CardInterface) {
    if (state.navigation.actividad?.id === card.id) {
      return
    }

    state.navigation = Object.assign(state.navigation, {
      actividad: card,
      vehiculo: undefined,
      turno: undefined,
      date: undefined,
      datosPersonales: undefined,
      preDatos: (!state.authUser) ? undefined : state.navigation.preDatos
    })
  },
  COMPLETE_VEHICULO(state: VuexStore, value: CardInterface) {
    if (state.navigation.vehiculo?.id === value.id) {
      return
    }
    state.navigation = Object.assign(state.navigation, {
      vehiculo: value,
      turno: undefined,
      date: undefined,
      datosPersonales: undefined,
      preDatos: (!state.authUser) ? undefined : state.navigation.preDatos
    })
  },
  COMPLETE_TURNO(state: VuexStore, data: { turno: CardInterface, dia: object }) {

    if (state.navigation.turno?.id === data.turno.id) {
      return
    }
    state.navigation = Object.assign(state.navigation, {
      turno: data.turno,
      date: data.dia,
      datosPersonales: undefined,
      preDatos: (!state.authUser) ? undefined : state.navigation.preDatos
    })
  },
  CLEAR_TURNO(state: VuexStore) {
    state.navigation = Object.assign(state.navigation, {
      turno: undefined,
      date: undefined,
      datosPersonales: undefined,
    })
  },
  CLEAR_TURNO_SOLO(state: VuexStore) {
    state.navigation = Object.assign(state.navigation, {
      turno: undefined,
    })
  },
  COMPLETE_DATOS_PERSONALES(state: VuexStore, value: object) {
    state.navigation = Object.assign(state.navigation, {
      datosPersonales: value
    })
  },
  COMPLETE_PREDATOS(state: VuexStore, value: PredatosData) {
    state.navigation = Object.assign(state.navigation, {
      preDatos: value
    })
  },
  CLEAR_PREDATOS(state: VuexStore) {
    state.navigation = Object.assign(state.navigation, {
      preDatos: undefined
    })
  },
  SEND_FORM_COMPLETE(state: VuexStore) {
    state.navigation = Object.assign(state.navigation, {
      complete: true
    })
  },
  CLEAR_FORM_COMPLETE(state: VuexStore) {
    state.navigation = Object.assign(state.navigation, {
      complete: undefined
    })
  },
  RESET_NAV(state: VuexStore) {
    state.navigation = {}
  },
  SET_RESERVA(state: VuexStore, codigo: string) {
    state.navigation = Object.assign(state.navigation, {
      codigoReserva: codigo,
    })
  },
  SET_RESERVA_ESTADO(state: VuexStore, status?: number) {
    state.navigation = Object.assign(state.navigation, {
      estadoReserva: status,
    })
  },
  SET_CONTACTO_DIRECTO(state: VuexStore, value: boolean) {
    state.directContact = value
  }
}

export default mutations
