import axios from '@/plugins/axios'
import { store } from '@/setup/store'
import { disableInterceptorOk } from './HeaderNoInterceptors'
// import { EventoState } from '@/interfaces/VuexStore'

export interface Config {
  analyticId: string
  emblema: string
  eventoId: number
  logo: string
  logo_email: string
  nombre: string
  theme: string
  vigente: boolean
  porInvitacion: boolean
}

const loadStyle = (url: string) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  document.getElementsByTagName('head')[0].appendChild(link)
}
const loadTitle = (title: string) => {
  document.title = title
}

export const resolveHtml = async (): Promise<Config> => {
  const { data } = await axios.get<Config>(
    '/eventos/config',
    disableInterceptorOk
  )
  //  if (data.eventoId) {
  //    const { data: locaciones } = await axios.get<locationInterface[]>(
  //      `front/locaciones/listar`,
  //      disableInterceptorOk
  //    )

  //    if (locaciones.length === 1) {
  //      const locacionId = locaciones[0].id
  //      const { data: actividades } = await axios.get<ActivitiesI[]>(
  //        `/front/locaciones/${locacionId}/actividades/listar`,
  //        disableInterceptorOk
  //      )

  //      if (actividades.length === 1 && actividades[0].tipo === 'Contacto') {
  //        const actividadId = actividades[0].id

  //        const { data: vehiculos } = await axios.get<VehiculoInterface[]>(
  //          `/front/actividades/${actividadId}/vehiculos`,
  //          disableInterceptorOk
  //        )

  //        if (vehiculos.length === 1) {
  //          store.commit('COMPLETE_UBICACION', locaciones[0])
  //          store.commit('COMPLETE_ACTIVIDAD', actividades[0])
  //          store.commit('COMPLETE_VEHICULO', vehiculos[0])
  //          // store.commit('COMPLETE_PREDATOS', { email: '@' })
  //          store.commit('COMPLETE_PREDATOS', { email: '' })
  //          store.commit('SET_CONTACTO_DIRECTO', true)

  //        } 
  //      } 
  //    } 
  //  } 

  if (typeof data.theme === 'undefined') {
    throw 'No config'
  }

  loadStyle('/css/' + data.theme + '/root.css')
  const linkElement = document.querySelector<HTMLLinkElement>('link[rel=icon]')
  if (linkElement) {
    linkElement.href = '/icon/' + data.theme + '/favicon.png'
  }

  if (!data.porInvitacion) {
    data.porInvitacion = false
  }

  store.commit('SET_EVENT', data)
  loadTitle(data.nombre ?? store.state.evento?.nombre)

  return data
}

// export const resolverContactForm = async (
//   eventoId: number
// ): Promise<boolean> => {
//   // const router = useRouter()

//   if (eventoId) {
//     const { data: locaciones } = await axios.get<locationInterface[]>(
//       `front/locaciones/listar`,
//       disableInterceptorOk
//     )

//     if (locaciones.length === 1) {
//       const locacionId = locaciones[0].id
//       const { data: actividades } = await axios.get<ActivitiesI[]>(
//         `/front/locaciones/${locacionId}/actividades/listar`,
//         disableInterceptorOk
//       )

//       if (actividades.length === 1 && actividades[0].tipo === 'Contacto') {
//         const actividadId = actividades[0].id

//         const { data: vehiculos } = await axios.get<VehiculoInterface[]>(
//           `/front/actividades/${actividadId}/vehiculos`,
//           disableInterceptorOk
//         )


//         if (vehiculos.length === 1) {
//           store.commit('COMPLETE_UBICACION', locaciones[0])
//           store.commit('COMPLETE_ACTIVIDAD', actividades[0])
//           store.commit('COMPLETE_VEHICULO', vehiculos[0])
//           // store.commit('COMPLETE_PREDATOS', { email: '@' })
//           store.commit('COMPLETE_PREDATOS', { email: '' })
//           store.commit('SET_CONTACTO_DIRECTO', true)

//           return true
//         } else {
//           return false
//         }
//       } else {
//         return false
//       }
//     } else {
//       return false
//     }
//   } else {
//     return false
//   }
// }
