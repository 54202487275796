import { VuexStore } from '@/interfaces/VuexStore'

const getters = {
  actualUbicacionURL: (state: VuexStore) => {
    if (state.navigation.ubicacion?.id) {
      return {
        name: 'DetailUbicaciones',
        params: {
          id: state.navigation.ubicacion.id,
          actividadId: state.navigation.actividad?.id
        }
      }
    }
    return { name: 'Home' }
  },
  actualActividadURL: (state: VuexStore) => {
    if (state.navigation.ubicacion?.id && state.navigation.actividad?.id) {
      return {
        name: 'DetailActividades',
        params: {
          id: state.navigation.ubicacion.id,
          actividadId: state.navigation.actividad.id,
          seleccionId: state.navigation.vehiculo?.id
        }
      }
    }

    return {}
  },
  actualActividadConVehiculoURL: (state: VuexStore, getters: any) => {
    if (
      state.navigation.ubicacion?.id &&
      state.navigation.actividad?.id &&
      state.navigation.vehiculo?.id
    ) {
      return {
        name:
          getters.tipoActividad === 'contacto'
            ? 'DetailVehiculo'
            : 'DetailActividades_TURNOS',
        params: {
          id: state.navigation.ubicacion.id,
          actividadId: state.navigation.actividad.id,
          vehiculoId: state.navigation.vehiculo.id
        }
      }
    }

    return {}
  },
  tipoActividad: (state: VuexStore) => {

    return state.navigation.actividad?.tipo.toLocaleLowerCase().trim()
    // // reservabel pero no reserva una unidad (trae la suya)
    // // if (
    // //   state.navigation.actividad?.reservable &&
    // //   !state.navigation.actividad?.reservaUnidad
    // // ) {
    // //   return 'academy'
    // // }

    // // reservable pero y reserva unidad a testeraer
    // if (
    //   state.navigation.actividad?.reservable &&
    //   state.navigation.actividad?.reservaUnidad
    // ) {
    //   return 'testdrive'
    // }

    // // if (!state.navigation.actividad?.seleccionaVehiculo) {
    // //   return 'contacto_vehicular'
    // // }

    // if (
    //   !state.navigation.actividad?.reservable &&
    //   !state.navigation.actividad?.reservaUnidad
    // ) {
    //   return 'contacto'
    // }

    // if (
    //   !state.navigation.actividad?.reservable &&
    //   !state.navigation.actividad?.reservaUnidad
    // ) {
    //   return 'driving'
    // }

    // return ''
  },
  actualFormularioURL: (state: VuexStore, getters: any) => {
    if (
      getters.tipoActividad &&
      state.navigation.ubicacion?.id &&
      state.navigation.actividad?.id &&
      (['contacto_vehicular'].includes(getters.tipoActividad.toString()) ||
        (['contacto'].includes(getters.tipoActividad.toString()) &&
          state.navigation.vehiculo?.id) ||
        (['academy', 'academy_fidelizacion', 'recital', 'cupos_limitados'].includes(getters.tipoActividad.toString()) &&
          state.navigation.turno?.id) ||
        (['testdrive', 'travesia'].includes(getters.tipoActividad.toString()) &&
          state.navigation.turno?.id &&
          state.navigation.vehiculo?.id))
    ) {
      return {
        name: 'Solicitudes_' + getters.tipoActividad
      }
    }

    return {}
  },
  resumenURL: (state: VuexStore, getters: any) => {
    if (state.navigation.datosPersonales && state.navigation.complete) {
      return {
        name:
          'Solicitudes_' + getters.tipoActividad.toString() + '_confirmacion'
      }
    }

    return {}
  },
  intereses: (state: VuexStore) => {
    return {
      actividadId: state.navigation.actividad?.id,
      versionInteresId: state.navigation.vehiculo?.id ?? null,
      turnoId: state.navigation.turno?.id ?? undefined
    }
  },
  isLogin: (state: VuexStore) => {
    return Object.keys(state.authUser ?? {}).length
  },
  porInvitacion: (state: VuexStore) => {
    return state.evento?.porInvitacion
  },
  isComplete: (state: VuexStore) => {
    return state.navigation.complete ?? false
  },
  isDirectContact: (state: VuexStore) => {
    return state.directContact
  },
  activity: (state: VuexStore) => {
    return state.navigation.actividad
  }
}

export default getters
