
import { defineComponent, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import FormProvinceWithDealer from '@/components/form/FormProvinceWithDealer.vue'
import FormLocalitiesWithDealer from '@/components/form/FormLocalitiesWithDealer.vue'
import FormDealer from '@/components/form/FormDealer.vue'
import FormContactHour from '@/components/form/FormContactHour.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'

export interface DealerDAta {
  provincia: number
  localidad: number
  dealer: number
}

export default defineComponent({
  components: {
    FormProvinceWithDealer,
    FormLocalitiesWithDealer,
    FormDealer,
    FormContactHour
  },
  props: {
    modelValue: {
      type: Object as () => DealerDAta
    },
    labelName: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    const formulario = reactive({
      provincia: null,
      localidad: null,
      concesionarioId: null,
      momentoContacto: 0
    })

    const { state, getters } = useStore<VuexStore>(key)

    emit('update:modelValue', formulario)

    watch(formulario, (value) => {
      emit('update:modelValue', value)
    })

    return {
      t,
      formulario,
      state,
      getters
    }
  }
})
