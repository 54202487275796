<template>
  <div class="container p-5">
    <div class="row">
      <div class="col-12">
        <h4 class="text-center">BASES Y CONDICIONES</h4>
      </div>

      <div class="col-12">
        <h5><b>1. ORGANIZADOR DEL SORTEO</b></h5>
        <p>
          El presente sorteo es organizado y promovido por
          <b>PEUGEOT CITROEN ARGENTINA S.A.</b>, titular del CUIT Nº
          30-50474453-8, con domicilio en Carlos María Della Paolera 265 de la
          Ciudad Autónoma de Buenos Aires (en adelante, el "Organizador"), quien
          es el único responsable de la mecánica, desarrollo y proceso de
          selección final del Ganador. Sin perjuicio de ello, una vez entregado
          el Premio, el Organizador se deslinda de toda responsabilidad
          vinculada con el uso y/o goce de los premios entregados. Las personas
          intervinientes en la Promoción, por su sola participación, aceptan de
          pleno derecho y de forma incondicional las disposiciones de estas
          Bases. Cualquier consulta o reclamo relacionado con el sorteo se podrá
          realizar al WhatsApp +54 9 11 6840-2635 o al 0800 777 2487, en días
          hábiles de 8 a 20 hs.
        </p>
      </div>

      <div class="col-12">
        <h5><b>2. BASES DE PARTICIPACIÓN</b></h5>
        <p>
          Estas "Bases y Condiciones de Participación del sorteo" constituyen
          los requisitos particulares necesarios para participar en el sorteo
          <b>“Entradas Sideshow JPEGMAFIA”</b>. odos los aspectos relativos a
          las condiciones de participación, vigencia, duración, funcionamiento
          del sorteo y premios se regirán por lo aquí dispuesto.
        </p>
      </div>

      <div class="col-12">
        <h5><b>3. ACEPTACIÓN DE LAS BASES DE LA PROMOCIÓN</b></h5>
        <p>
          La participación en este sorteo supone la aceptación plena e
          incondicional de todas las condiciones aquí fijadas. La aceptación de
          estas bases es requisito indispensable para participar. Quienes no
          acepten o no estén de acuerdo con cualquier disposición aquí
          establecida, no podrán participar del sorteo. Las decisiones del
          ORGANIZADOR sobre todos y cada uno de los aspectos de la promoción
          serán definitivas e inapelables. La participación es libre, gratuita y
          sin obligación de compra, no estando sujeta al pago de ningún canon,
          derecho o suma dineraria alguna. La obtención del premio depende del
          cumplimiento de los requisitos establecidos en el presente documento.
        </p>
      </div>

      <div class="col-12">
        <h5><b>4. CONDICIONES PARA SER PARTICIPANTE</b></h5>
        <p>
          Podrán participar en el sorteo todas las personas mayores de 18 años,
          residentes en territorio argentino, que sean clientes Citroën
          registrados en el
          <a href="https://citroenclub.com.ar/">Citroën Club</a>. Para validar
          la condición de cliente de la marca, los participantes deberán
          completar el formulario de registro y subir la fotografía de la
          documentación de su vehículo, para lo cual PEUGEOT CITROEN ARGENTINA
          S.A. realizará la validación en un plazo de 24 hs hábiles. Tanto si la
          registración resulta aprobada como si es rechazada, se notificará vía
          correo electrónico.
        </p>
      </div>

      <div class="col-12">
        <h5><b>5. MECÁNICA DE PARTICIPACIÓN</b></h5>
        <p>
          Queda comprendida la participación en el sorteo al completar el
          registro en el Citroën Club, y suscribirse a la participación del
          sorteo lo que otorgará automáticamente una chance para participar del
          sorteo de un par de entradas para el Sideshow de JPEGMAFIA.
        </p>
        <ul>
          <li>
            El participante ganador deberá acreditar su identidad al momento de
            retirar el premio, exhibiendo su DNI.
          </li>
        </ul>

        <p>
          El ORGANIZADOR no se hace responsable por inconvenientes en el
          registro o solicitud de inscripción a la promoción, ni por retrasos o
          fallas en la recepción de mensajes electrónicos que sean causados por
          el mal funcionamiento técnico de la aplicación del sorteo, de las
          líneas o redes telefónicas, de los proveedores de acceso a Internet
          y/o por causas ajenas al ORGANIZADOR.
        </p>
      </div>

      <div class="col-12">
        <h5><b>5. 1. EXCLUSIÓN.</b></h5>
        <p>
          Quedan excluidos de participar de la Promoción personas jurídicas,
          personas humana domiciliadas fuera del Ámbito Geográfico, los
          directores, funcionarios, accionistas o empleados del Organizador, o
          de las sociedades controlantes o controladas del Organizador, o
          sujetas a control común con el Organizador o de sus agencias de
          publicidad o marketing o de cualquier otro proveedor del Organizador
          vinculado directa o indirectamente a esta Promoción, ni sus
          respectivos cónyuges y/o sus parientes por consanguinidad hasta el
          tercer grado inclusive, ni tampoco los exdirectores, funcionarios,
          accionistas o empleados de esas empresas que se hubieren desvinculado
          dentro de los treinta (30) días anteriores a la entrada en vigencia de
          la Promoción (en adelante las "Personas Excluidas")
        </p>
      </div>

      <div class="col-12">
        <h5><b>6. PLAZOS Y FECHAS</b></h5>
        <ul>
          <li><b>Inicio del Sorteo:</b> 00 hs del 15/02/2025</li>
          <li>
            <b> Cierre del Sorteo: </b> 23:59 hs del 19/03/2025. (Con
            posibilidad de registrarse en el Citroën Club hasta las 00 hs del
            20/03/2025)
          </li>
          <li>
            <b> Realización del Sorteo: </b> 10 hs del 21/03/2025, mediante
            sistema automático de sorteo que asignará en primer lugar los pases
            generales y posteriormente los pases VIP.
          </li>
          <li>
            <b>Comunicación del/los Ganador(es):</b> Los ganadores se darán a
            conocer el 21/03/2025 a través de la plataforma Citroën Club y serán
            notificados por correo electrónico, en el cual se les informarán las
            indicaciones para canjear el código ganador por las entradas físicas
            (pulseras).
          </li>
        </ul>
      </div>

      <div class="col-12">
        <h5><b>7. PREMIOS</b></h5>
        <p>
          Entre todos los participantes que cumplan con las condiciones
          establecidas en estas bases se sortearán los siguientes premios:
        </p>
        <ul>
          <li>
            <b
              >10 pares de entradas para el SideoShow de JPEGMAFIA el 25/3 en
              Niceto Club</b
            >
          </li>
        </ul>
        <p>
          Las entradas no podrán ser canjeadas por dinero, ni por ningún otro
          premio o beneficio.
        </p>
        <h5>
          <b> 7.1 CUESTIONES GENERALES DE LOS PREMIOS </b>
        </h5>
        <p>
          Los premios detallados en los puntos precedentes, no incluyen ningún
          otro bien o servicio distinto de los mencionados en las presentes
          Bases, es personal del Ganador e intransferible. Los premios no
          incluyen ninguna otra prestación, bien, servicio o garantía no
          enumerados en estas Bases y Condiciones. En ningún supuesto se
          cambiará total o parcialmente el premio por dinero en efectivo o algún
          otro bien o servicio. Todo concepto no comprendido en el premio estará
          a cargo y cuenta exclusivo del Ganador, sin responsabilidad alguna
          para el Organizador.
        </p>
      </div>

      <div class="col-12">
        <h5><b>8. MODALIDAD DE SORTEO</b></h5>
        <p>
          El sorteo se realizará el día 20/03/2025 a las 10 hs mediante un
          sistema electrónico automático. El sistema verificará que el
          participante haya cumplido con la mecánica de participación y con las
          presentes bases y condiciones; en caso de detectar incumplimientos, se
          descalificará la participación correspondiente y se procederá a elegir
          un nuevo registro al azar, hasta completar la asignación de los
          premios. Los nombres de los ganadores se publicarán en la plataforma
          Citroën Club. Al participar, los concursantes autorizan al ORGANIZADOR
          a difundir y/o publicar sus nombres y/o datos personales en la
          plataforma y en redes sociales, sin compensación alguna, para fines
          promocionales.
        </p>
      </div>

      <div class="col-12">
        <h5><b>9. ENTREGA DEL PREMIO</b></h5>
        <p>
          Los ganadores recibirán sus entradas a través de un QR en la app
          Quentro
        </p>
      </div>

      <div class="col-12">
        <h5>
          <b> 10. USO DE IMÁGENES Y TESTIMONIOS </b>
        </h5>
        <p>
          Tanto los Participantes como los Ganadores, como condición de
          asignación del premio y participación de la Promoción, autorizan al
          Organizador a publicar y/o difundir sus datos personales, imágenes y
          voces, así como sus fotos y videos presentados en el marco de la
          Promoción, dando a conocer sus usuarios de Instagram, con fines
          publicitarios en los medios y formas que el Organizador considere
          convenientes, durante la vigencia de la Promoción hasta cumplidos los
          tres (3) años de su finalización, sin derecho a reclamo de
          indemnización o compensación alguna. Los Participantes y
          correspondiente Ganador garantizan que no existen terceros que tengan
          derechos de exclusividad sobre la exhibición, publicación, difusión,
          reproducción o la puesta en el comercio de su imagen, voz o datos
          personales, y se comprometen a mantener indemne al Organizador
          respecto de cualquier reclamo que por este motivo pudieran formular
          terceras personas.
        </p>
      </div>

      <div class="col-12">
        <h5>
          <b> 11. TRATAMIENTO DE LOS DATOS PERSONALES </b>
        </h5>
        <p>
          Los datos personales recabados serán utilizados única y exclusivamente
          para fines informativos relacionados con el Citroën Club y la
          comunicación de resultados del sorteo, de conformidad con la Ley
          Argentina de Protección de Datos Personales y demás normativas
          vigentes.
        </p>
        <p>
          La participación en el sorteo implica el consentimiento expreso del
          participante para dicho tratamiento de datos.
        </p>
      </div>

      <div class="col-12">
        <h5>
          <b> 12. MODIFICACIÓN DE LAS CONDICIONES </b>
        </h5>
        <p>
          El ORGANIZADOR se reserva el derecho de modificar, suspender o
          cancelar el sorteo en caso de fuerza mayor u otras circunstancias
          imprevistas. Cualquier modificación se comunicará oportunamente a
          través de la plataforma Citroën Club.
        </p>
        <p>
          Asimismo, el ORGANIZADOR podrá modificar las presentes bases hasta un
          plazo máximo de 5 horas antes del cierre del sorteo, sin que ello
          genere responsabilidad alguna
        </p>

        <p>
          Cuando circunstancias no imputables al Organizador ni previstas en
          estas Bases o que constituyan caso fortuito o fuerza mayor así lo
          justifiquen, el Organizador podrá cancelar, suspender o modificar los
          términos de la Promoción lo que será comunicado al público en general
          por los mismos medios por los que se dieron a conocer estas Bases.
        </p>
      </div>

      <div class="col-12">
        <h5>
          <b> 13. EXCLUSIÓN DE RESPONSABILIDAD </b>
        </h5>
        <p>
          La participación en este sorteo implica la aceptación de las
          condiciones aquí dispuestas y la renuncia expresa a efectuar reclamos,
          directos o indirectos, relacionados con la participación, entrega o
          retiro de premios.
        </p>

        <p>
          El Organizador no será responsable por eventuales daños y/o perjuicios
          que pudieren sufrir los Participantes/Ganador, o los terceros, en sus
          personas o bienes, o las personas que hagan uso de los Premios con
          motivo y/u ocasión de la participación en la presente Promoción y/o en
          ocasión de la utilización del Premio, ni será responsable del destino
          que el Participante Ganador le otorgue al Premio, declinando todo tipo
          de responsabilidad contractual y/o extracontractual frente a los
          Participantes, Participantes/Ganador y/o familiares, lo que es
          aceptado incondicionalmente por cada Participante. El Organizar no
          otorga ninguna garantía distinta de la garantía legal establecida en
          la Ley 24.240 de Defensa del Consumidor con relación al Premio puestos
          en juego. La responsabilidad del Organizador finaliza con la puesta a
          disposición del Participante Ganador del premio. Asimismo, el
          ORGANIZADOR no es responsable de la organización del festival
          Lollapalooza ni de cualquier suceso que ocurra durante el mismo.
        </p>
        <p>
          El Organizador no garantiza la disponibilidad y continuidad del
          funcionamiento de las redes sociales descriptas anteriormente, ni
          tampoco garantiza que los Participantes puedan efectivamente acceder a
          ellas, siendo esto responsabilidad exclusiva de las prestadoras de
          servicios de internet o de las redes que fueran necesarias a los
          efectos de su utilización
        </p>

        <p>
          El Organizador no se responsabiliza por el uso que los Participantes
          hicieran de las redes. Tampoco tienen la obligación de verificar la
          identidad de los Participantes, ni la veracidad, vigencia, y/o
          autenticidad de los datos que los Participantes proporcionan sobre sí
          mismos a otros seguidores.
        </p>
        <p>
          Los Participantes serán responsables de toda la información enviada.
        </p>
      </div>

      <div class="col-12">
        <h5>
          <b> 14. GASTOS ADICIONALES </b>
        </h5>
        <p>
          Los gastos no mencionados en las presentes Bases y Condiciones y/o
          todo otro gasto adicional en el que incurran los Participante/es,
          serán a cargo de estos. Estará a cargo del Participante Ganador toda
          tasa, impuesto, patente, sellado y/o contribución que deba tributarse
          sobre o en relación con el Premios, como así también los gastos en que
          incurran los Ganadores cuando hagan uso de sus Premios.
        </p>
      </div>

      <div class="col-12">
        <h5><b>15. LEY APLICABLE Y JURISDICCIÓN</b></h5>
        <p>
          Todo conflicto que en virtud de la Promoción se pudiera generar entre
          los Participantes y el Organizador será sometido a la legislación
          argentina y dirimido por ante los Tribunales Nacionales Comerciales
          con asiento en la Ciudad Autónoma de Buenos Aires, sin excepción y
          renunciando a cualquier otra jurisdicción que pudiere eventualmente
          corresponder
        </p>
      </div>
    </div>
  </div>
</template>
