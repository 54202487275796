
import { defineComponent, onMounted, ref, watch } from 'vue'
import AppSelectCard from '@/components/AppSelectCard.vue'
import { activityService } from '@/services/activity.service'
import LoadingSkeletonDetail from '@/skeletor/LoadingSkeletonDetail.vue'
import { vehicleService } from '@/services/vehicle.service'
import { useRoute, useRouter } from 'vue-router'
import TheReserveDay from '@/views/detail/TheReserveDay.vue'
import { useI18n } from 'vue-i18n'
import { CardInterface } from '@/interfaces/CardInterface'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key } from '@/setup/store'
import AppContentButtonsComponent from '@/components/AppContentButtonsComponent.vue'
import TheCupones from '@/components/TheCupones.vue'
import useNavigationComplete from '@/composables/useNavigationComplete'
import DetailSeccion from '@/views/detail/DetailSeccion.vue'
import TheSorteo from '@/components/TheSorteo.vue'

export default defineComponent({
  components: {
    DetailSeccion,
    AppContentButtonsComponent,
    TheCupones,
    TheReserveDay,
    AppSelectCard,
    LoadingSkeletonDetail,
    TheSorteo
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { navigation } = useNavigationComplete()
    const { getters, state, commit } = useStore<VuexStore>(key)
    const service = ref<object>()
    const actividadVer = ref()
    const loading = ref(true)
    const { t } = useI18n()
    const error = ref(false)
    const turnosVip = ref<boolean | null>(null)
    const habilitarGeneral = new Date() >= new Date('2024-01-15T00:00:00')
    const resolveService = async () => {
      try {
        error.value = false
        loading.value = true
        service.value = undefined

        /** resolver informacion */
        const idActividad = parseInt(route.params?.actividadId.toString()) ?? 0
        const dataActividad = await activityService.ver(idActividad)

        actividadVer.value = dataActividad

        loading.value = false
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }

    watch(
      () => route.params.actividadId,
      async (idRoute) => {
        if (!idRoute) {
          return
        }
        resolveService()
      }
    )

    onMounted(() => {
      resolveService()
    })

    const completeSelection = (data: CardInterface) => {
      commit('COMPLETE_VEHICULO', data)
      router.push({
        name: 'DetailActividades',
        params: {
          id: route.params.id,
          actividadId: route.params.actividadId,
          seleccionId: data.id
        }
      })
    }

    const totalCard = ref(0)

    const ready = (total: any) => {
      totalCard.value = total
    }

    const verTurnosVip = (vip: boolean) => {
      turnosVip.value = vip
    }

    return {
      loading,
      service,
      actividadVer,
      activityService,
      t,
      completeSelection,
      state,
      getters,
      navigation,
      route,
      vehicleService,
      error,
      ready,
      totalCard,
      turnosVip,
      verTurnosVip,
      habilitarGeneral
    }
  }
})
