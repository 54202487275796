
import { defineComponent, reactive } from 'vue'
import { Form, useForm } from 'vee-validate'
import router from '@/router'
import AppButtonContinue from '@/components/buttons/AppButtonContinue.vue'
import AppButtonReturn from '@/components/buttons/AppButtonReturn.vue'
import { useStore } from 'vuex'
import { VuexStore } from '@/interfaces/VuexStore'
import { key, store } from '@/setup/store'
import PersonalDate, { PersonalDateData } from '@/views/datos/part/PersonalDate.vue'
import AppInfoTitles from '@/components/AppInfoTitles.vue'
import DealerSelection from '@/views/datos/part/DealerSelection.vue'
import { formulariosService } from '@/services/formularios.service'
import useLoading from '@/composables/useLoading'
import { predatosService } from '@/services/predatos.service'

export default defineComponent({
  components: {
    DealerSelection,
    AppInfoTitles,
    PersonalDate,
    Form,
    AppButtonContinue,
    AppButtonReturn
  },
  setup() {
    const { commit, state, getters} = useStore<VuexStore>(key)
    const { handleSubmit } = useForm()
    const { loading, setSearch } = useLoading(false)
    window.scrollTo(0, 0)

    const form = reactive<{personal:PersonalDateData, dealerInfo: any, interesadoId: string | undefined}>({
      personal: {} as PersonalDateData,
      dealerInfo: {},
      interesadoId: state.navigation.preDatos?.interesadoId
    })

    const onSubmit = handleSubmit(async () => {
      try {
        if (getters.isDirectContact) {
          const id = await predatosService.sendPredatos(
            form.personal?.email,
            store.getters.intereses
          )
          form.interesadoId = id.toString()
          form.dealerInfo.momentoContacto = 0
          store.commit('COMPLETE_PREDATOS', { email: form.personal?.email, interesadoId: id })
        }

        setSearch(true, 'Procesando tu solicitud...')
        await formulariosService.contacto(form)
        await commit('COMPLETE_DATOS_PERSONALES', form)
        await commit('SEND_FORM_COMPLETE')

        await router.push({ name: 'Solicitudes_contacto_confirmacion' })
      } catch (error) {
        setSearch(false, '')
      }
    })

    return {
      onSubmit,
      form,
      loading
    }
  }
})
